import axios from "./axios";
import { OrderDbType } from "../models/order_model";
import { UserType } from "../models/user_model";

export const getOrderApi = async (id: string): Promise<OrderDbType | false> => {
  const user: UserType = JSON.parse(localStorage.getItem("user") || "{}");
  try {
    const res = await axios.post(`/scriptsOrdersGet`, {
      order_id: id,
      user_id: user.id,
    });
    console.log("order.tsx:31 | res", res);
    return { ...res.data };
  } catch (error) {
    console.log("order.tsx:25 | error", error);
    return false;
  }
};
