import React from "react";
import ShippingInfo from "./shipping-info";
import PaymentInfo from "./payment-info";
import Button from "../../components/buttons";
import { OrderType } from "../../models/checkout";

type ResumeSectionProps = {
  makeOrder: () => void;
  order: OrderType;
  loading: boolean;
};
const ResumeSection: React.FC<ResumeSectionProps> = ({
  makeOrder,
  order,
  loading,
}) => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  ///TODO: Victor, please improve the ui of this component
  return (
    <div className="step-four">
      <div className="pr-0 pr-md-5">
        <div className="confirmation-item">
          <ShippingInfo
            deliveryData={order.address}
            selectedBranch={order.selectedBranch}
          />
          <div className="btn-change"></div>
        </div>
        <div className="confirmation-item">
          <PaymentInfo payment_type={order.payment_type} />
          <div className="btn-change"></div>
        </div>
        {user && user.accounts ? (
          <div className="confirmation-item">
            <div className="payment-info pb-3 pt-3">
              <h4>Facturar a:</h4>
              <select
                className="form-control w-100"
                onChange={(e) => {
                  order.regrfc = e.target.value;
                }}
              >
                <option value={user.email}>
                  {user.regrfc} | {user.displayName}
                </option>
                {user.accounts.map((account: any) => (
                  <option
                    key={`account_${account.email}`}
                    value={account.email}
                  >
                    {account.rfc} | {account.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : null}
        <div className="confirmation-item">
          <div className="payment-info pb-3 pt-3">
            <h4>Numero de Orden de Compra</h4>
            <input
              type="text"
              className="form-control"
              placeholder="(Opcional)"
              value={order.noocmp}
              onChange={(e) => {
                order.noocmp = e.target.value;
              }}
            />
          </div>
        </div>
        <Button
          onClick={makeOrder}
          children={
            loading
              ? `Realizando Orden de Compra...`
              : `Realizar Orden de Compra por $${order.totals.total}`
          }
          buttonclasses="primary mt-2"
          width="80%"
          icon=""
          title="Submit"
          disabled={loading}
        />
        <p className="text-center " style={{ width: "80%" }}>
          <small>
            Una vez realizada la orden de compra, nuestro equipo se pondrá en
            contacto con usted para los pasos siguientes
          </small>
        </p>
      </div>
    </div>
  );
};

export default ResumeSection;
