import { Totals } from "../middleware/total";
import { AddressType } from "./address";
import { BranchItem } from "./branch_model";
import { CardData } from "./checkout_model";

export type OrderType = {
  delivery_type: DeliveryTypes | false;
  payment_type: PaymentTypes | false;
  address?: AddressType;
  selectedBranch?: BranchItem;
  billing: any | false;
  products: OrderProduct[];
  noocmp?: string;
  card?: CardData;
  products_data: {
    desc: string;
    total: number;
  };
  status: string;
  regrfc?: string;
  timestamp: string;
  totals: Totals;
  user: OrderUser;
};

export type ErrorType = {
  message: string;
  code?: number;
};

export type DeliveryTypes =
  | "delivery"
  | "plane"
  | "land"
  | "pallet"
  | "pickup"
  | "delivery_charge";
export type PaymentTypes = "online" | "transfer" | "credit" | false;
export type SectionTypes =
  | "delivery"
  | "address"
  | "branch-selection"
  | "plane"
  | "payment"
  | "resume"
  | "thankyou"
  | "order-received"
  | "empty_cart";

export type OrderUser = {
  email: string;
  lastname: string;
  name: string;
};

export type OrderProduct = {
  cant: number;
  details: OrderProductDetails[];
  id: string;
  idpart: string;
  fconvs?: number;
  image?: string;
  price: number;
  title: string;
  unit: string;
};

export type OrderProductDetails = {
  name: string;
  value: string;
};

export type SectionBreadcrumbs = {
  id: SectionTypes;
  name: string;
  title: string;
};

export const section_types: SectionTypes[] = [
  "delivery",
  "address",
  "branch-selection",
  "plane",
  "payment",
  "resume",
  "thankyou",
  "order-received",
  "empty_cart",
];

export const section_breadcrumbs: SectionBreadcrumbs[] = [
  {
    id: "delivery",
    name: "Forma de Entrega",
    title: "Selecciona Forma de Entrega",
  },
  {
    id: "address",
    name: "Información de Envío",
    title: "Dirección de Envío",
  },
  {
    id: "payment",
    name: "Forma de Pago",
    title: "Selecciona Forma de Pago",
  },
  {
    id: "resume",
    name: "Confirmación",
    title: "Confirmar y Realizar Orden de Compra",
  },
];
