import { useAsync } from "react-async-hook";
import { db } from "../middleware/firebase";

export type ProductTag = {
  productTagId: string;
  primaryType: string;
  secondaryType: string;
  tertiaryType: string;
  count: Number;
  products: string[];
};

export async function getProductTags() {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const productTagsRef = db.collection("product-tags");
  let query = productTagsRef.orderBy("primaryType");

  if (user.priceListId) {
    query = productTagsRef.where(
      "priceLists",
      "array-contains",
      user.priceListId
    );
  }
  const productTags: ProductTag[] = (await query.get()).docs.map((doc) =>
    doc.data()
  ) as any;

  const tagsDictionary: {
    [primaryType: string]: { [secondaryType: string]: string[] };
  } = {};

  productTags.forEach(({ primaryType, secondaryType, tertiaryType }) => {
    if (!tagsDictionary[primaryType]) {
      tagsDictionary[primaryType] = {};
    }
    const tertiaryTags = tagsDictionary[primaryType][secondaryType] || [];

    tagsDictionary[primaryType][secondaryType] = [
      ...tertiaryTags,
      tertiaryType,
    ];

    // If primaryType is 'Lúpulos', sort tertiaryType in descending order
    if (primaryType === "Lúpulos") {
      tagsDictionary[primaryType][secondaryType].sort((a, b) =>
        b.localeCompare(a)
      );
    }
  });

  delete tagsDictionary[""];

  return tagsDictionary;
}

export default function useProductTags() {
  return useAsync(getProductTags, []);
}
