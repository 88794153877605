import React, { useState } from "react";
import { Link } from "react-router-dom";
import useProductTags from "../../hooks/useProductTags";

type ProductsNavProps = {
  active: boolean;
  toggleNav: () => void;
};
const ProductsNav: React.FC<ProductsNavProps> = ({ active, toggleNav }) => {
  const [activeTag, setActiveTag] = useState<string | undefined>();
  const { result: productsTags } = useProductTags();
  if (!productsTags) {
    return null;
  }

  return (
    <div className={`products_menu ${active ? "d-flex" : "d-none"}`}>
      <ul>
        {Object.keys(productsTags).map((tag) => {
          return (
            <li
              key={tag}
              className={`animate_all ${activeTag === tag && "active"}`}
              data-id={tag}
              onMouseEnter={(ev) => {
                const id = ev.currentTarget.dataset.id;
                setActiveTag(id);
              }}
            >
              <Link
                to={`/productos/${replaceWords(tag)}`}
                onClick={toggleNav}
                className="d-flex justify-content-between align-items-center"
              >
                {tag} <i className="fas fa-chevron-right"></i>
              </Link>
            </li>
          );
        })}
      </ul>
      <div className="menu_items">
        {activeTag &&
          Object.entries(productsTags[activeTag]).map(
            ([secondaryTag, tertiaryTags]) => (
              <div className="childs" key={`menu_2_${secondaryTag}`}>
                <Link
                  to={`/productos/${replaceWords(activeTag)}/${replaceWords(
                    secondaryTag
                  )}`}
                  onClick={toggleNav}
                >
                  {secondaryTag}
                </Link>
                {tertiaryTags.length ? (
                  <ul>
                    {tertiaryTags.map((tertiaryTag) => {
                      return (
                        <li key={`menu_3_${secondaryTag}_${tertiaryTag}`}>
                          <Link
                            to={`/productos/${replaceWords(
                              activeTag
                            )}/${replaceWords(secondaryTag)}/${replaceWords(
                              tertiaryTag
                            )}`}
                            onClick={toggleNav}
                          >
                            {tertiaryTag}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                ) : null}
              </div>
            )
          )}
      </div>
    </div>
  );
};

function replaceWords(word: string) {
  // word = word.split(" ").join("-");
  // word = encodeURIComponent(word);
  return word.replace("Ba%C3%B1o", "Bano");
}

export default ProductsNav;
